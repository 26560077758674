<template>
  <div>
    <b-card
      overlay
      class="d-none d-md-block"
      :img-src="backgroundImg"
      img-alt="Card Image"
      :text-variant="txtColor"
      style="border: none"
    >
      <b-container
        class="px-0 w-100 h-100"
        :class="
          orientation === 'center'
            ? 'mt-5'
            : orientation === 'left'
            ? 'ml-5'
            : orientation === 'right'
            ? 'ml-auto mr-5'
            : ''
        "
      >
        <b-row
          v-if="title"
          :class="!text && !learnMore && !tryFunc ? 'h-100' : ''"
        >
          <b-col
            align-self="center"
            :class="
              orientation === 'center'
                ? 'text-center'
                : orientation === 'left'
                ? 'text-left'
                : 'text-right'
            "
            :cols="12"
            :md="orientation === 'center' ? 12 : colsMD ? colsMD : 7"
            :offset-md="
              orientation === 'right' ? (colsMD ? 12 - colsMD : 5) : 0
            "
            :style="paddingTop ? 'padding-top: ' + paddingTop + ';' : ''"
          >
            <h1
              :style="
                titleSize ? 'font-size: ' + titleSize + ';' : 'font-size: 3vw;'
              "
            >
              <strong><div v-html="title" /></strong>
            </h1>
          </b-col>
        </b-row>
        <b-row v-if="text">
          <b-col
            :class="
              orientation === 'center'
                ? 'text-center'
                : orientation === 'left'
                ? 'text-left'
                : 'text-right'
            "
            :cols="12"
            :md="orientation === 'center' ? 12 : colsMD ? colsMD : 7"
            :offset-md="
              orientation === 'right' ? (colsMD ? 12 - colsMD : 5) : 0
            "
          >
            <p
              :style="textSize ? 'font-size: ' + textSize : 'font-size: 23px'"
              v-html="text"
            />
          </b-col>
        </b-row>
        <b-row v-if="learnMore">
          <b-col
            :class="
              orientation === 'center'
                ? 'text-center'
                : orientation === 'left'
                ? 'text-left'
                : 'text-right'
            "
            :cols="12"
            :md="orientation === 'center' ? 12 : colsMD ? colsMD : 7"
            :offset-md="
              orientation === 'right' ? (colsMD ? 12 - colsMD : 5) : 0
            "
          >
            <b-button
              class="d-block font-weight-bold"
              style="background-color: white"
              :variant="btnVariant"
              @click="learnMore"
              pill
              size="lg"
              block
            >
              Conocer más
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="tryFunc">
          <b-col
            :class="
              orientation === 'center'
                ? 'text-center'
                : orientation === 'left'
                ? 'text-left'
                : 'text-right'
            "
            :cols="12"
            :md="orientation === 'center' ? 12 : colsMD ? colsMD : 7"
            :offset-md="
              orientation === 'right' ? (colsMD ? 12 - colsMD : 5) : 0
            "
          >
            <b-button
              class="d-block font-weight-bold"
              :variant="btnVariant"
              @click="tryFunc"
              style="background-color: white"
              pill
              size="lg"
              block
            >
              Pruébalo gratis!
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card
      overlay
      class="d-block d-md-none"
      :img-src="backgroundImgCell"
      img-alt="Card Image"
      :text-variant="txtColor"
      style="border: none"
    >
      <b-container
        class="px-0 w-100"
        :class="orientation === 'right' ? 'ml-auto mr-5' : ''"
      >
        <b-row v-if="title">
          <b-col
            :class="
              orientation === 'center'
                ? 'text-left'
                : orientation === 'left'
                ? 'text-left'
                : 'text-right'
            "
            :cols="12"
            :style="
              paddingTopCell ? 'padding-top: ' + paddingTopCell + ';' : ''
            "
          >
            <h1
              :style="
                titleSizeCell ? 'font-size: ' + titleSizeCell : 'font-size: 9vw'
              "
            >
              <strong><div v-html="title" /></strong>
            </h1>
          </b-col>
        </b-row>
        <b-row v-if="text">
          <b-col
            :class="
              orientation === 'center'
                ? 'text-left'
                : orientation === 'left'
                ? 'text-left'
                : 'text-right'
            "
            :cols="12"
          >
            <p
              :style="
                textSizeCell ? 'font-size: ' + textSizeCell : 'font-size: 6vw'
              "
              v-html="text"
            />
          </b-col>
        </b-row>
        <b-row v-if="learnMore">
          <b-col
            :class="
              orientation === 'center'
                ? 'text-center'
                : orientation === 'left'
                ? 'text-left'
                : 'text-right'
            "
            :cols="12"
          >
            <b-button
              class="d-block font-weight-bold"
              style="background-color: white"
              :variant="btnVariant"
              @click="learnMore"
              pill
              size="lg"
              block
            >
              Conocer más
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<script>
export default {
  props: [
    "backgroundImg",
    "backgroundImgCell",
    "orientation",
    "title",
    "text",
    "tryFunc",
    "learnMore",
    "txtColor",
    "btnVariant",
    "titleSize",
    "textSize",
    "titleSizeCell",
    "textSizeCell",
    "colsMD",
    "paddingTop",
    "paddingTopCell",
  ],
  data() {
    return {};
  },
};
</script>
<style scoped>
body {
  font-family: Ubuntu, "times new roman", times, roman, serif !important;
}
</style>
