<template>
  <b-container fluid="true" class="start">
    <b-row v-if="route === 'redpie'">
      <b-col sm="10" lg="6" class="m-lg-4 p-lg-4 p-sm-1 m-sm-1">
        <h1 class="font-weight-bold" style="margin-top: 0.2em">
          La solución a los problemas de la educación especial
        </h1>
        <h5>Tú trabajas con los niños, <b>nosotros hacemos el resto.</b></h5>
        <!-- <b-button href="#pricing" variant="outline-white" class="text-white mt-4 button-contrata" pill>
          <b>Contrata Ahora</b>
        </b-button> -->
      </b-col>
    </b-row>
    <info-banner
      v-else-if="route === 'eureka'"
      :backgroundImg="startImg"
      :backgroundImgCell="startImgCell"
      orientation="left"
      title="Eureka es una evaluación psicopedagógica digital"
      txtColor="white"
      titleSize="5vw"
      colsMD="12"
    />
    <info-banner
      v-else-if="route === 'planification'"
      :backgroundImg="startImg"
      :backgroundImgCell="startImgCell"
      orientation="left"
      title="Planificaciones de clase, diversificadas automáticamente"
      txtColor="white"
      titleSize="5vw"
      titleSizeCell="35px"
      paddingTopCell="40px"
      colsMD="12"
    />
    <!-- <b-img :src="startImg" fluid v-else/> -->
  </b-container>
</template>
<script>
import InfoBanner from "@/components/utopie/miscellaneous/InfoBanner";

export default {
  components: { InfoBanner },
  name: "Start",
  props: {
    route: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    startImg() {
      var imgs = {
        eureka: require("@/assets/img/eureka_sale/PNG/web/banner eureka.png"),
        planification: require("@/assets/img/planification_sale/PNG/web/Planificaciones_1_banner planif@4x.png"),
      };
      return imgs[this.route];
    },
    startImgCell() {
      var imgs = {
        eureka: require("@/assets/img/eureka_sale/PNG/cel/banner eureka.png"),
        planification: require("@/assets/img/planification_sale/PNG/cel/Planificaciones_1_banner planif@4x.png"),
      };
      return imgs[this.route];
    },
  },
};
</script>
<style scoped>
.start {
  background-image: url("~@/assets/img/sales/banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  color: white;
  padding: 0%;
}
.button-contrata {
  border-width: 3px;
  border-color: white;
}
</style>
