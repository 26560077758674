<template>
  <b-container>
    <b-modal
        ref="add-element-modal"
        centered
        hide-footer
    >
      <b-container>
        <b-row>
          <h4>Agregar</h4>
        </b-row>
        <b-row>
          <multiselect
              v-if="typeInput === 'grade'"
              v-model="elementToAdd"
              :group-select="true"
              :multiple="true"
              :options="gradesList"
              deselectLabel="Presiona INTRO para remover"
              group-label="label"
              group-values="levels"
              label="name"
              placeholder="Añadir más cursos"
              select-label="Seleccionar"
              track-by="name"
          >
            <span slot="noOptions">No puedes agregar mas cursos</span>
            <span slot="noResult">No se ha encontrado ningún curso</span>
          </multiselect>

          <multiselect
              v-if="typeInput === 'diagnostic'"
              v-model="elementToAdd"
              :group-select="true"
              :multiple="true"
              :options="diagnosesList"
              deselectLabel="Presiona INTRO para remover"
              group-label="label"
              group-values="diagnoses"
              label="name"
              placeholder="Añadir más diagnósticos"
              select-label="Seleccionar"
              track-by="name"
          >
            <span slot="noOptions">No puedes agregar mas diagnósticos</span>
            <span slot="noResult">No se ha encontrado ningún diagnóstico</span>
          </multiselect>

          <b-form-file
              v-if="typeInput === 'template'"
              v-model="elementToAdd"
              :file-name-formatter="formatNames"
              :state="elementToAdd.length>0"
              drop-placeholder="Arrastrar archivos aquí..."
              multiple
              placeholder="Templates..."
          ></b-form-file>

        </b-row>
        <b-row>
          <b-button variant="success" @click="addElement">
            Listo!
          </b-button>
        </b-row>
      </b-container>
    </b-modal>
    <b-row>
      <b-col cols="1" class="m-0 p-0">
        <b-container class="m-0 p-0">
          <b-row class="m-0 p-0">
            <b-col class="m-0 p-0 text-center">
              <v-icon name="caret-up" v-if="dtIndex" class="cursor-pointer" scale="1" @click="$emit('move-up')"/>
            </b-col>
          </b-row>
          <b-row class="m-0 p-0">
            <b-col class="m-0 p-0 text-center">
              <v-icon name="caret-down" v-if="!last" class="cursor-pointer" scale="1" @click="$emit('move-down')"/>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col cols="1" class="m-0 p-0">
        <b-button pill variant="primary" @click="migrateDocuments" v-if="$store.state.login.isAdmin">
          Migrar
        </b-button>
      </b-col>
      <b-col cols="9" style="padding-left: 0px !important;">
        <b-list-group-item button class="text-center" @click="visible = !visible;">
          {{ docType.name }}
        </b-list-group-item>
        <b-collapse
            :visible="visible"
            accordion="my-accordion"
        >
          <b-card class="mb-1" no-body>
            <b-card-body>
              <b-card-text>
                <b-container>
                  <b-row>
                    <b-col align-h="end" cols="3">
                      <b-button
                          :disabled="$store.state.login.schoolLevel < 1"
                          size="sm"
                          @click="saveChanges"
                      >
                        <v-icon name="save"/>
                        Guardar Cambios
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h6><strong>Cursos</strong></h6>

                      <ul class="custom-list">

                        <li
                            v-for="(grade, index) in docType.grades"
                            v-bind:key="index"
                        >
                          {{ getGrade(grade) }}
                          <b-badge
                              v-if="$store.state.login.schoolLevel > 0"
                              variant="danger"
                              @click="deleteElement(index, grade, docType.grades, deletedGrades)"
                          >
                            <v-icon name="times"/>
                          </b-badge>
                        </li>

                        <li
                            v-for="(grade, index2) in addedGrades"
                            v-bind:key="index2+'-added'"
                            style="color: blue !important;"
                        >
                          {{ getGrade(grade) }}
                          <b-badge
                              variant="danger"
                              @click="deleteElement(index2, grade, addedGrades, [])"
                          >
                            <v-icon name="times"/>
                          </b-badge>
                        </li>

                        <li
                            v-for="(grade, index3) in deletedGrades"
                            v-bind:key="index3+'-deleted'"
                            style="color: red !important;"
                        >
                          {{ getGrade(grade) }}
                          <b-badge
                              variant="info"
                              @click="deleteElement(index3, grade, deletedGrades, docType.grades)"
                          >
                            <v-icon name="trash-restore"/>
                          </b-badge>
                        </li>
                      </ul>
                      <b-row
                          v-if="$store.state.login.schoolLevel > 0"
                          style="text-align: left !important; cursor: pointer;"
                          @click="openAddElement('grade')"
                      >
                        <b-col cols="1">
                          <b-badge variant="primary">
                            <v-icon name="plus"/>
                          </b-badge>
                        </b-col>
                        <b-col class="option-column" cols="9">
                          Agregar curso
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col>
                      <h6><strong>Diagnósticos</strong></h6>

                      <ul class="custom-list">

                        <li
                            v-for="(diagnostic, index) in docType.diagnoses"
                            v-bind:key="index"
                        >
                          {{ getDiagnostic(diagnostic) }}
                          <b-badge
                              v-if="$store.state.login.schoolLevel > 0"
                              variant="danger"
                              @click="deleteElement(index, diagnostic, docType.diagnoses, deletedDiagnoses)"
                          >
                            <v-icon name="times"/>
                          </b-badge>
                        </li>

                        <li
                            v-for="(diagnostic, index2) in addedDiagnoses"
                            v-bind:key="index2+'-added'"
                            style="color: blue !important;"
                        >
                          {{ getDiagnostic(diagnostic) }}
                          <b-badge
                              variant="danger"
                              @click="deleteElement(index2, diagnostic, addedDiagnoses, [])"
                          >
                            <v-icon name="times"/>
                          </b-badge>
                        </li>

                        <li
                            v-for="(diagnostic, index3) in deletedDiagnoses"
                            v-bind:key="index3+'-deleted'"
                            style="color: red !important;"
                        >
                          {{ getDiagnostic(diagnostic) }}
                          <b-badge
                              variant="info"
                              @click="deleteElement(index3, diagnostic, deletedDiagnoses, docType.diagnoses)"
                          >
                            <v-icon name="trash-restore"/>
                          </b-badge>
                        </li>

                      </ul>
                      <b-row
                          v-if="$store.state.login.schoolLevel > 0"
                          style="text-align: left !important; cursor: pointer;"
                          @click="openAddElement('diagnostic')"
                      >
                        <b-col cols="1">
                          <b-badge variant="primary">
                            <v-icon name="plus"/>
                          </b-badge>
                        </b-col>
                        <b-col class="option-column" cols="9">
                          Agregar diagnóstico
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col>
                      <h6><strong>Templates</strong></h6>

                      <ul class="custom-list">

                        <li
                            v-for="(template, index) in docType.templates"
                            v-bind:key="index"
                        >
                          <div v-if="!editNames">
                            <b-link @click="downloadTemplate(template)">{{ template.name }}</b-link>
                            <b-badge
                                v-if="$store.state.login.schoolLevel > 0"
                                variant="danger"
                                @click="deleteElement(index, template, docType.templates, deletedTemplates)"
                            >
                              <v-icon name="times"/>
                            </b-badge>
                          </div>
                          <b-form-input
                              v-else
                              v-model="template.name"
                              size="sm"
                          />
                        </li>

                        <li
                            v-for="(template, index2) in addedTemplates"
                            v-bind:key="index2+'-added'"
                            style="color: blue !important;"
                        >
                          <div v-if="!editNames">
                            {{
                              template.customName === "" ?
                                  template.file.name :
                                  template.customName
                            }}
                            <b-badge
                                variant="danger"
                                @click="deleteElement(index2, template, addedTemplates, [])"
                            >
                              <v-icon name="times"/>
                            </b-badge>
                          </div>
                          <b-form-input
                              v-else
                              v-model="template.customName"
                              size="sm"
                          />
                        </li>

                        <li
                            v-for="(template, index3) in deletedTemplates"
                            v-bind:key="index3+'-deleted'"
                            style="color: red !important;"
                        >
                          <div v-if="!editNames">
                            {{ template.name }}
                            <b-badge
                                variant="info"
                                @click="deleteElement(index3, template, deletedTemplates, docType.templates)"
                            >
                              <v-icon name="trash-restore"/>
                            </b-badge>
                          </div>
                          <b-form-input
                              v-else
                              v-model="template.name"
                              size="sm"
                          />
                        </li>

                      </ul>
                      <b-row
                          v-if="$store.state.login.schoolLevel > 0"
                          style="text-align: left !important; cursor: pointer;"
                          @click="editNames = !editNames"
                      >
                        <b-col cols="1">
                          <b-badge :variant="!editNames? '' : 'success'">
                            <v-icon name="pen"/>
                          </b-badge>
                        </b-col>
                        <b-col class="option-column" cols="9">
                          {{ !editNames ? 'Editar nombres' : 'Dejar de editar' }}
                        </b-col>
                      </b-row>
                      <b-row
                          v-if="!editNames && $store.state.login.schoolLevel > 0"
                          style="text-align: left !important; cursor: pointer;"
                          @click="openAddElement('template')"
                      >
                        <b-col cols="1">
                          <b-badge variant="primary">
                            <v-icon name="plus"/>
                          </b-badge>
                        </b-col>
                        <b-col class="option-column" cols="9">
                          Agregar template
                        </b-col>
                      </b-row>
                      
                      <div v-if="docType.is_multi">
                        <h6><strong>Templates Online</strong></h6>

                        <ul class="custom-list">

                          <li
                            v-for="(template_online, to_index) in docType.online_templates"
                            v-bind:key="to_index"
                            style="cursor: pointer;"
                            @click="$emit('edit-online-template', docType.id, template_online.document_template_online)"
                          >
                            {{template_online.name}}
                            <b-badge
                              v-if="$store.state.login.schoolLevel > 0"
                            >
                              <v-icon name="pen"/>
                            </b-badge>
                          </li>
                        </ul>
                        <b-row
                          style="text-align: left !important; cursor: pointer;"
                          @click="$emit('edit-online-template', docType.id, false)"
                        >
                          <b-col cols="1">
                            <b-badge variant="primary">
                              <v-icon name="plus"/>
                            </b-badge>
                          </b-col>
                          <b-col class="option-column" cols="9">
                            Agregar template online
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-collapse>
      </b-col>
      <b-col cols="1" style="padding-left: 0px !important; padding-right: 0px">
        <a
            v-if="$store.state.login.schoolLevel > 0"
            class="text-danger mt-0"
            @click="deleteDocumentType"
        >
          <v-icon name="times"/>
        </a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import redpieService from "../../../services/redpieService";

export default {
  data() {
    return {
      deletedGrades: [],
      deletedTemplates: [],
      deletedDiagnoses: [],
      addedGrades: [],
      addedTemplates: [],
      addedDiagnoses: [],
      typeInput: "",
      elementToAdd: [],
      editNames: false,
      visible: false,
    }
  },
  props: {
    docType: {
      type: Object,
      required: true,
    },
    grades: {
      type: Array,
      required: true,
    },
    diagnoses: {
      type: Array,
      required: true,
    },
    gradesList: {
      type: Array,
      required: true,
    },
    diagnosesList: {
      type: Array,
      required: true,
    },
    dtIndex: {
      type: Number,
      required: true
    },
    last: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    migrateDocuments() {
      redpieService.migrateDocuments({document_type: this.docType.id}).then(
        data => {
          this.$toasted.success(data.message);
        },
        error => {
          if (error.response && error.response.data && error.response.data.message){
            this.$toasted.error(error.response.data.message);
          }
        }
      )
    },
    getGrade: function (code) {
      for (var i = 0; i < this.grades.length; i++) {
        if (this.grades[i][0] === code) {
          return this.grades[i][1];
        }
      }
    },
    getDiagnostic: function (code) {
      for (var i = 0; i < this.diagnoses.length; i++) {
        if (this.diagnoses[i][0] === code) {
          return this.diagnoses[i][1];
        }
      }
    },
    saveChanges: function () {
      let formData = new FormData();
      formData.append("document_type", this.docType.id)
      for (var i = 0; i < this.deletedGrades.length; i++) {
        formData.append("deleted_grades", this.deletedGrades[i]);
      }
      for (var j = 0; j < this.deletedDiagnoses.length; j++) {
        formData.append("deleted_diagnoses", this.deletedDiagnoses[j]);
      }
      for (var k = 0; k < this.deletedTemplates.length; k++) {
        formData.append("deleted_templates", this.deletedTemplates[k].id);
      }
      for (var x = 0; x < this.docType.templates.length; x++) {
        formData.append("templates", this.docType.templates[x].id);
        formData.append("templates_names", this.docType.templates[x].name);
      }
      for (var y = 0; y < this.addedGrades.length; y++) {
        formData.append("added_grades", this.addedGrades[y]);
      }
      for (var z = 0; z < this.addedDiagnoses.length; z++) {
        formData.append("added_diagnoses", this.addedDiagnoses[z]);
      }
      for (var a = 0; a < this.addedTemplates.length; a++) {
        formData.append("added_templates", this.addedTemplates[a].file);
        formData.append("added_templates_names", this.addedTemplates[a].customName)
      }
      redpieService.editDocumentType(formData).then(
          data => {
            this.$emit("reload")
            this.docType = data.document_type;
            this.resetValues();
            this.$toasted.success(data.message);
          }
      )
    },
    deleteElement: function (index, element, list, delArray) {
      delArray.push(element);
      list.splice(index, 1);
    },
    openAddElement: function (element) {
      this.typeInput = element;
      this.elementToAdd = [];
      this.$refs["add-element-modal"].show();
    },
    downloadTemplate(template) {
      redpieService.downloadTemplate({template_id: template.id}).then(
        response => {
            var filename = "";
            var disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                  filename = matches[1].replace(/['"]/g, '');
                }
            }
            var blob = new Blob([response.data], {type: response.headers['content-type']});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
          }
      )
    },
    addElement: function () {
      if (this.typeInput === "grade") {
        for (var i = 0; i < this.elementToAdd.length; i++) {
          if (this.docType.grades.indexOf(this.elementToAdd[i].code) === -1)
            this.addedGrades.push(this.elementToAdd[i].code);
        }
      }
      if (this.typeInput === "diagnostic") {
        for (var j = 0; j < this.elementToAdd.length; j++) {
          if (this.docType.diagnoses.indexOf(this.elementToAdd[j].code) === -1)
            this.addedDiagnoses.push(this.elementToAdd[j].code);
        }
      }
      if (this.typeInput === "template") {
        for (var k = 0; k < this.elementToAdd.length; k++) {
          this.addedTemplates.push(
              {
                file: this.elementToAdd[k],
                customName: this.elementToAdd[k].name
              });
        }
      }
      this.elementToAdd = [];
      this.typeInput = "";
      this.$refs["add-element-modal"].hide();
    },
    deleteDocumentType: function () {
      this.$emit('deleteDocumentType')
    },
    formatNames: function (files) {
      if (files.length === 1) {
        return files[0].name
      } else {
        return `${files.length} archivos`
      }
    },
    resetValues: function () {
      this.deletedGrades = [];
      this.deletedTemplates = [];
      this.deletedDiagnoses = [];
      this.addedGrades = [];
      this.addedTemplates = [];
      this.addedDiagnoses = [];
      this.typeInput = "";
      this.elementToAdd = [];
      this.editNames = false;
    },
  }
}
</script>

<style lang="scss">
.custom-list {
  padding-left: 0px !important;
  text-align: left !important;
}

.option-column {
  font-size: 15px;
}
.cursor-pointer{
  cursor: pointer;
}

.cursor-pointer:hover{
  color: #f8b50099 !important;
}
</style>
