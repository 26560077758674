var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"d-none d-md-block",staticStyle:{"border":"none"},attrs:{"overlay":"","img-src":_vm.backgroundImg,"img-alt":"Card Image","text-variant":_vm.txtColor}},[_c('b-container',{staticClass:"px-0 w-100 h-100",class:_vm.orientation === 'center'
          ? 'mt-5'
          : _vm.orientation === 'left'
          ? 'ml-5'
          : _vm.orientation === 'right'
          ? 'ml-auto mr-5'
          : ''},[(_vm.title)?_c('b-row',{class:!_vm.text && !_vm.learnMore && !_vm.tryFunc ? 'h-100' : ''},[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',style:(_vm.paddingTop ? 'padding-top: ' + _vm.paddingTop + ';' : ''),attrs:{"align-self":"center","cols":12,"md":_vm.orientation === 'center' ? 12 : _vm.colsMD ? _vm.colsMD : 7,"offset-md":_vm.orientation === 'right' ? (_vm.colsMD ? 12 - _vm.colsMD : 5) : 0}},[_c('h1',{style:(_vm.titleSize ? 'font-size: ' + _vm.titleSize + ';' : 'font-size: 3vw;')},[_c('strong',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.title)}})])])])],1):_vm._e(),(_vm.text)?_c('b-row',[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"cols":12,"md":_vm.orientation === 'center' ? 12 : _vm.colsMD ? _vm.colsMD : 7,"offset-md":_vm.orientation === 'right' ? (_vm.colsMD ? 12 - _vm.colsMD : 5) : 0}},[_c('p',{style:(_vm.textSize ? 'font-size: ' + _vm.textSize : 'font-size: 23px'),domProps:{"innerHTML":_vm._s(_vm.text)}})])],1):_vm._e(),(_vm.learnMore)?_c('b-row',[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"cols":12,"md":_vm.orientation === 'center' ? 12 : _vm.colsMD ? _vm.colsMD : 7,"offset-md":_vm.orientation === 'right' ? (_vm.colsMD ? 12 - _vm.colsMD : 5) : 0}},[_c('b-button',{staticClass:"d-block font-weight-bold",staticStyle:{"background-color":"white"},attrs:{"variant":_vm.btnVariant,"pill":"","size":"lg","block":""},on:{"click":_vm.learnMore}},[_vm._v(" Conocer más ")])],1)],1):_vm._e(),(_vm.tryFunc)?_c('b-row',[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"cols":12,"md":_vm.orientation === 'center' ? 12 : _vm.colsMD ? _vm.colsMD : 7,"offset-md":_vm.orientation === 'right' ? (_vm.colsMD ? 12 - _vm.colsMD : 5) : 0}},[_c('b-button',{staticClass:"d-block font-weight-bold",staticStyle:{"background-color":"white"},attrs:{"variant":_vm.btnVariant,"pill":"","size":"lg","block":""},on:{"click":_vm.tryFunc}},[_vm._v(" Pruébalo gratis! ")])],1)],1):_vm._e()],1)],1),_c('b-card',{staticClass:"d-block d-md-none",staticStyle:{"border":"none"},attrs:{"overlay":"","img-src":_vm.backgroundImgCell,"img-alt":"Card Image","text-variant":_vm.txtColor}},[_c('b-container',{staticClass:"px-0 w-100",class:_vm.orientation === 'right' ? 'ml-auto mr-5' : ''},[(_vm.title)?_c('b-row',[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-left'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',style:(_vm.paddingTopCell ? 'padding-top: ' + _vm.paddingTopCell + ';' : ''),attrs:{"cols":12}},[_c('h1',{style:(_vm.titleSizeCell ? 'font-size: ' + _vm.titleSizeCell : 'font-size: 9vw')},[_c('strong',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.title)}})])])])],1):_vm._e(),(_vm.text)?_c('b-row',[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-left'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"cols":12}},[_c('p',{style:(_vm.textSizeCell ? 'font-size: ' + _vm.textSizeCell : 'font-size: 6vw'),domProps:{"innerHTML":_vm._s(_vm.text)}})])],1):_vm._e(),(_vm.learnMore)?_c('b-row',[_c('b-col',{class:_vm.orientation === 'center'
              ? 'text-center'
              : _vm.orientation === 'left'
              ? 'text-left'
              : 'text-right',attrs:{"cols":12}},[_c('b-button',{staticClass:"d-block font-weight-bold",staticStyle:{"background-color":"white"},attrs:{"variant":_vm.btnVariant,"pill":"","size":"lg","block":""},on:{"click":_vm.learnMore}},[_vm._v(" Conocer más ")])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }