<template>
  <div class="form-container">
    <p class="instruction">Ingresa solo las respuestas INCORRECTAS</p>
    <form @submit.prevent="handleSubmit">
      <!-- Selección de Edad -->
      <div class="form-group">
        <label for="edad">Edad:</label>
        <select v-model="answer.answers.edad" required>
          <option value="">---------</option>
          <option value="3">3.0 - 3.11 años</option>
          <option value="4">4.0 - 4.11 años</option>
          <option value="5">5.0 - 5.11 años</option>
          <option value="6">6.0 - 6.11 años</option>
        </select>
      </div>

      <!-- Lista de Preguntas -->
      <div v-for="(pair, index) in questionPairs" :key="index">
        <!-- Títulos "Receptivo" y "Expresivo" -->
        <template v-if="index === 0">
          <h2 class="section-title">Receptivo</h2>
        </template>
        <template v-else-if="index === 23">
          <h2 class="section-title">Expresivo</h2>
        </template>

        <div class="question-pair">
          <div class="question-row">
            <!-- Número del par de preguntas -->
            <div class="question-number">{{ getQuestionNumber(pair[0].var) }}.</div>

            <!-- Pregunta 1 -->
            <div class="question">
              <label>
                <input
                  type="checkbox"
                  v-model="answer.answers[pair[0].var]"
                />
                <span v-html="formatLabel(pair[0].label)"></span>
              </label>
            </div>
          </div>

          <div class="question-row question-second">
            <!-- Espacio para alinear con el número -->
            <div class="question-number"></div>

            <!-- Pregunta 2 -->
            <div class="question" v-if="pair[1]">
              <label>
                <input
                  type="checkbox"
                  v-model="answer.answers[pair[1].var]"
                />
                <span v-html="formatLabel(pair[1].label)"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Botón de Envío -->
      <button type="submit">Enviar</button>
    </form>
  </div>
</template>

<script>
import redpieService from "../../../../services/redpieService";
import testService from "../../../../services/testService";

export default {
  props: ["test_id", "student_id", "previous_answer", "semester"],
  data() {
    return {
      answer: { test: this.test_id, answers: {}, student_id: this.student_id },
      required: ["edad"],
      questions: [
        // Lista completa de preguntas (asegúrate de incluir todas)
        { label: "El niño está sentado*:", type: "checkbox", var: "r_1_1" },
        { label: "El niño no está sentado", type: "checkbox", var: "r_1_2" },
        {
          label: "El gato está encima de la caja",
          type: "checkbox",
          var: "r_2_1",
        },
        {
          label: "El gato está adentro de la caja*",
          type: "checkbox",
          var: "r_2_2",
        },
        { label: "El está subiendo", type: "checkbox", var: "r_3_1" },
        { label: "Ella está subiendo*", type: "checkbox", var: "r_3_2" },
        {
          label: "El perro está detrás de la silla*",
          type: "checkbox",
          var: "r_4_1",
        },
        {
          label: "El perro está debajo de la silla",
          type: "checkbox",
          var: "r_4_2",
        },
        { label: "Están comiendo", type: "checkbox", var: "r_5_1" },
        { label: "Está comiendo*", type: "checkbox", var: "r_5_2" },
        { label: "El libro es de él*", type: "checkbox", var: "r_6_1" },
        { label: "El libro es de ella", type: "checkbox", var: "r_6_2" },
        { label: "El niño se cayó*", type: "checkbox", var: "r_7_1" },
        { label: "El niño se cae", type: "checkbox", var: "r_7_2" },
        { label: "Alguien está en la mesa", type: "checkbox", var: "r_8_1" },
        { label: "Algo está en la mesa*", type: "checkbox", var: "r_8_2" },
        { label: "El niño la está llamando", type: "checkbox", var: "r_9_1" },
        {
          label: "El niño lo está llamando*",
          type: "checkbox",
          var: "r_9_2",
        },
        { label: "Este es mi papá*", type: "checkbox", var: "r_10_1" },
        { label: "Aquel es mi papá", type: "checkbox", var: "r_10_2" },
        {
          label: "El niño está tomando helado*",
          type: "checkbox",
          var: "r_11_1",
        },
        {
          label: "El niño estaba tomando helado",
          type: "checkbox",
          var: "r_11_2",
        },
        { label: "¿Dónde está la niña?", type: "checkbox", var: "r_12_1" },
        { label: "¿Quién es la niña?*", type: "checkbox", var: "r_12_2" },
        {
          label: "El niño tiene el pájaro*",
          type: "checkbox",
          var: "r_13_1",
        },
        { label: "El niño tenía el pájaro", type: "checkbox", var: "r_13_2" },
        { label: "La niña las tiene*", type: "checkbox", var: "r_14_1" },
        { label: "La niña la tiene", type: "checkbox", var: "r_14_2" },
        { label: "Esta es mi cama", type: "checkbox", var: "r_15_1" },
        { label: "Esta es nuestra cama*", type: "checkbox", var: "r_15_2" },
        { label: "El niño se ve", type: "checkbox", var: "r_16_1" },
        { label: "El niño lo ve*", type: "checkbox", var: "r_16_2" },
        { label: "La niña subirá*", type: "checkbox", var: "r_17_1" },
        { label: "La niña subió", type: "checkbox", var: "r_17_2" },
        { label: "Mira a quien llegó", type: "checkbox", var: "r_18_1" },
        { label: "Mira lo que llegó*", type: "checkbox", var: "r_18_2" },
        {
          label: "La mamá dice, 'Se lo dio'",
          type: "checkbox",
          var: "r_19_1",
        },
        {
          label: "La mamá dice, 'Me lo dio'*",
          type: "checkbox",
          var: "r_19_2",
        },
        {
          label: "La mamá va a comprar pan*",
          type: "checkbox",
          var: "r_20_1",
        },
        {
          label: "La mamá fue a comprar pan",
          type: "checkbox",
          var: "r_20_2",
        },
        { label: "Este es un avión*", type: "checkbox", var: "r_21_1" },
        { label: "Ese es un avión", type: "checkbox", var: "r_21_2" },
        { label: "El papá es alto", type: "checkbox", var: "r_22_1" },
        { label: "El papá está alto*", type: "checkbox", var: "r_22_2" },
        {
          label: "El niño es llamado por la niña*",
          type: "checkbox",
          var: "r_23_1",
        },
        {
          label: "La niña es llamada por el niño",
          type: "checkbox",
          var: "r_23_2",
        },
        // Omitimos { label: "R obs", type: "text", var: "r_obs", maxlength: "300" }

        // Preguntas E (reiniciamos la numeración y omitimos "E obs")
        { label: "La puerta no está cerrada*", type: "checkbox", var: "e_1_1" },
        { label: "La puerta está cerrada", type: "checkbox", var: "e_1_2" },
        {
          label: "El perro está encima del auto*",
          type: "checkbox",
          var: "e_2_1",
        },
        {
          label: "El perro está adentro del auto",
          type: "checkbox",
          var: "e_2_2",
        },
        {
          label: "El gato está debajo de la silla",
          type: "checkbox",
          var: "e_3_1",
        },
        {
          label: "El gato está detrás de la silla*",
          type: "checkbox",
          var: "e_3_2",
        },
        { label: "Él ve al gato*", type: "checkbox", var: "e_4_1" },
        { label: "Ella ve al gato", type: "checkbox", var: "e_4_2" },
        { label: "Alguien está en la silla", type: "checkbox", var: "e_5_1" },
        { label: "Algo está en la silla*", type: "checkbox", var: "e_5_2" },
        { label: "El sombrero es de ella", type: "checkbox", var: "e_6_1" },
        { label: "El sombrero es de él*", type: "checkbox", var: "e_6_2" },
        { label: "Está durmiendo", type: "checkbox", var: "e_7_1" },
        { label: "Están durmiendo*", type: "checkbox", var: "e_7_2" },
        { label: "El niño se vistió*", type: "checkbox", var: "e_8_1" },
        { label: "El niño se viste", type: "checkbox", var: "e_8_2" },
        {
          label: "La niña está escribiendo*",
          type: "checkbox",
          var: "e_9_1",
        },
        {
          label: "La niña estaba escribiendo",
          type: "checkbox",
          var: "e_9_2",
        },
        { label: "La niña la ve", type: "checkbox", var: "e_10_1" },
        { label: "La niña lo ve*", type: "checkbox", var: "e_10_2" },
        { label: "El niño tenía el globo*", type: "checkbox", var: "e_11_1" },
        { label: "El niño tiene el globo", type: "checkbox", var: "e_11_2" },
        { label: "La niña lo lleva*", type: "checkbox", var: "e_12_1" },
        { label: "La niña los lleva", type: "checkbox", var: "e_12_2" },
        { label: "Este es mi amigo", type: "checkbox", var: "e_13_1" },
        { label: "Aquel es mi amigo*", type: "checkbox", var: "e_13_2" },
        { label: "El niño lo lava", type: "checkbox", var: "e_14_1" },
        { label: "El niño se lava*", type: "checkbox", var: "e_14_2" },
        { label: "Este es su perro*", type: "checkbox", var: "e_15_1" },
        { label: "Este es nuestro perro", type: "checkbox", var: "e_15_2" },
        { label: "La niña comió*", type: "checkbox", var: "e_16_1" },
        { label: "La niña comerá", type: "checkbox", var: "e_16_2" },
        { label: "Esa es mi muñeca", type: "checkbox", var: "e_17_1" },
        { label: "Esta es mi muñeca*", type: "checkbox", var: "e_17_2" },
        {
          label: "¿Quién está en la puerta?*",
          type: "checkbox",
          var: "e_18_1",
        },
        { label: "¿Qué está en la puerta?", type: "checkbox", var: "e_18_2" },
        { label: "¿Dónde está el niño?", type: "checkbox", var: "e_19_1" },
        { label: "¿Quién es el niño?*", type: "checkbox", var: "e_19_2" },
        {
          label: "El niño va a cortarse el pelo",
          type: "checkbox",
          var: "e_20_1",
        },
        {
          label: "El niño fue a cortarse el pelo*",
          type: "checkbox",
          var: "e_20_2",
        },
        {
          label: "El niño dice, 'Me la dio'*",
          type: "checkbox",
          var: "e_21_1",
        },
        {
          label: "El niño dice, 'Se la dio'",
          type: "checkbox",
          var: "e_21_2",
        },
        { label: "El niño es alto", type: "checkbox", var: "e_22_1" },
        { label: "El niño está alto*", type: "checkbox", var: "e_22_2" },
        {
          label: "La niña es empujada por el niño*",
          type: "checkbox",
          var: "e_23_1",
        },
        {
          label: "El niño es empujado por la niña",
          type: "checkbox",
          var: "e_23_2",
        },
        // Omitimos { label: "E obs:", type: "text", var: "e_obs", maxlength: "300" }
      ],
      is_valid: null,
    };
  },
  computed: {
    questionPairs() {
      // Filtrar las preguntas, omitiendo "R obs:" y "E obs:"
      const filteredQuestions = this.questions.filter(
        (q) => q.label !== "R obs:" && q.label !== "E obs:"
      );

      // Agrupar las preguntas en pares
      const pairs = [];
      for (let i = 0; i < filteredQuestions.length; i += 2) {
        pairs.push([filteredQuestions[i], filteredQuestions[i + 1]]);
      }
      return pairs;
    },
  },
  methods: {
    formatLabel(label) {
      // Añadir el asterisco y poner en negrita las preguntas con asterisco
      if (label.includes('*')) {
        return '<b>' + label + '</b>';
      }
      return label;
    },
    getQuestionNumber(varName) {
      // Extraer el número central de la variable, ejemplo: de 'e_9_1' extraer '9'
      const parts = varName.split('_');
      return parts[1];
    },
    validateForm() {
      if (!this.answer.answers.edad) {
        alert("Debes completar el campo de edad");
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.validateForm()) {
        // Procesar el envío del formulario
        this.sendTest();
      }
    },
    sendTest() {
      // Extraer los campos requeridos
      const requiredFields = this.required;
      const answers = this.answer.answers;

      // Verificar que los campos requeridos estén completos
      const isValid = requiredFields.every((field) => answers[field]);
      this.is_valid = isValid;

      if (!isValid) {
        alert("Debes completar los campos requeridos.");
        return;
      }

      // Agregar el semestre a la respuesta
      this.answer.semester = this.semester;

      // Enviar los datos al servidor
      testService
        .send_test(this.answer)
        .then((data) => {
          // Asignar documentos relacionados
          redpieService.assignDocuments({
            test: this.answer.test,
            school: this.$store.state.login.school,
            year: this.$store.state.login.year,
            student_grade: this.answer.student_id,
          });

          // Emitir eventos para actualizar la información en el padre
          this.$emit("update-document");
          this.$emit("add-test", data);
        })
        .catch(() => {
          this.$toasted.error("Ha ocurrido un error al crear el test");
        });
    },
  },
  watch: {
    student_id() {
      this.answer.student_id = this.student_id;
    },
    test_id() {
      this.answer.test = this.test_id;
    },
    previous_answer: {
      handler(val) {
        this.$nextTick(() => {
          for (const [key, value] of Object.entries(val)) {
            this.$set(this.answer.answers, key, value);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (this.previous_answer && Object.keys(this.previous_answer).length) {
      for (const [key, value] of Object.entries(this.previous_answer)) {
        this.$set(this.answer.answers, key, value);
      }
    }
  },
};
</script>

<style scoped>
/* Estilos personalizados */

.form-container {
  background-color: #fff7e6;
  padding: 20px;
  border-radius: 8px;
}

.instruction {
  font-size: 16px;
  font-weight: bold;
  color: #d35400;
  margin-bottom: 15px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: normal;
  color: #5d5d5d;
}

select {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.section-title {
  font-size: 28px;
  font-weight: bold;
  color: #2c3e50;
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
}

.question-pair {
  margin-bottom: 15px;
}

.question-row {
  display: flex;
  align-items: flex-start;
}

.question-number {
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
  width: 30px;
}

.question {
  margin-bottom: 0px;
}

.question-second .question {
  margin-top: -4px; /* Solapar ligeramente la segunda pregunta */
}

input[type="checkbox"] {
  margin-right: 8px;
}

button {
  background-color: #F8B500;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

button:hover {
  background-color: #d35400;
}
</style>
