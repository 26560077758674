<template>
  <div>
    <b-container>
      <div>
        <b-tabs content-class="mt-3" justified v-model="tab_index">
          <b-tab
            v-for="(test, index) in filtered_tests"
            :key="index"
            :title="test.text"
            lazy
            @click="emptyPrevious"
          >
            <b-row v-if="!student.id">
              <b-card bg-variant="Light" class="w-100 text-center">
                <b-card-text
                  >Necesitas seleccionar un curso y estudiante para trabajar un
                  test.</b-card-text
                >
              </b-card>
            </b-row>
            <!-- <b-row>
                <b-button pill variant="primary" class="m-1"
                  @click="doing_test=true"
                  v-if="student.id && !doing_test"
                >
                  Trabajar {{ test.text }}
                </b-button>
              </b-row> -->
            <b-row v-if="last_test && !Object.keys(previousAnswers).length">
              <div class="mt-2">Último test {{ last_test.created_at }}</div>
              <b-button
                pill
                variant="primary"
                class="m-1"
                @click="getPreviousAnswers"
              >
                Editar
              </b-button>
              <b-button pill variant="danger" class="m-1" @click="deleteTest">
                Eliminar test
              </b-button>
            </b-row>
            <b-row
              v-if="
                (!last_test || Object.keys(previousAnswers).length) &&
                student.id
              "
            >
              <div class="test-div">
                <b-card bg-variant="Light">
                  <component
                    :semester="semester"
                    :is="test.component"
                    :student_id="student.id"
                    :test_id="actual_test"
                    @add-test="addTest"
                    @update-document="updateDocument"
                    :previous_answer="previousAnswers"
                  />
                </b-card>
              </div>
            </b-row>
            <b-row v-if="student.id">
              <b-card bg-variant="Light" class="w-100 text-center">
                <b-card-text
                  v-for="(doc, index) in documents_with_actual_test"
                  :key="index"
                  :set="(completed_tests[index] = completedTests(doc))"
                >
                  {{ doc.document_type.name }}: {{ completed_tests[index] }} /
                  {{ doc.document_type.document_info.required_tests.length }}
                  <span
                    class="d-inline-block"
                    tabindex="0"
                    v-b-tooltip="{
                      title: 'Debe tener al menos un test completado',
                      placement: 'top',
                      variant: 'danger',
                      disabled: completed_tests[index] > 0,
                    }"
                  >
                    <b-button
                      pill
                      variant="primary"
                      class="m-1"
                      :disabled="completed_tests[index] < 1"
                      @click="
                        workOnlineDocument(
                          doc,
                          completed_tests[index],
                          doc.document_type.document_info.required_tests.length
                        )
                      "
                    >
                      {{
                        doc.document_type.document_info.document
                          ? "Ver"
                          : "Crear"
                      }}
                      {{ doc.document_type.name }}
                    </b-button>
                  </span>
                </b-card-text>
              </b-card>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
    </b-container>
  </div>
</template>
<script>
import {
  Teprosif,
  Stsg,
  Idtel,
  // Pefe,
  Tecal,
  Wais4,
  Wisc,
  // Icap,
  Wisc5,
  Evalua,
  Evalua4,
  Evamat,
  Clpt,
  // Precalculo,
  // Funciones,
  Icap2,
  Sensorial,
} from "@/components/redpie/miscellaneous/form-test";
import testService from "@/services/testService";

export default {
  props: {
    student: {
      type: Object,
      required: false,
    },
    tests: {
      type: Array,
      required: false,
    },
    documents: {
      type: Array,
      required: false,
    },
    test_kind: {
      type: String,
      required: true,
    },
    preselected_test: {
      type: Number,
      required: false,
    },
    previous_test: {
      type: Number,
      required: false,
    },
    semester: {
      type: Number,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      school: {
        id: null,
      },
      previousAnswers: {},
      after_test: true,
      doing_test: false,
      tab_index: 0,
      tests_by_category: [
        {
          kind: "a",
          category: "Fonoaudiológico",
          tests: [
            { value: 3, text: "TEPROSIF", component: Teprosif },
            { value: 5, text: "STSG", component: Stsg },
            { value: 7, text: "IDTEL", component: Idtel },
            // { value: 8, text: "PEFE", component: Pefe },
            { value: 9, text: "TECAL", component: Tecal },
          ],
        },
        {
          kind: "b",
          category: "Psicológico",
          tests: [
            { value: 0, text: "WAIS4", component: Wais4 },
            { value: 1, text: "WISC", component: Wisc },
            // { value: 10, text: "ICAP", component: Icap },
            { value: 11, text: "WISC5", component: Wisc5 },
            { value: 15, text: "ICAP", component: Icap2 },
          ],
        },
        {
          kind: "c",
          category: "Psicopedagógico",
          tests: [
            { value: 2, text: "Evalúa Estándarización 3", component: Evalua },
            { value: 12, text: "Evalúa Estándarización 4", component: Evalua4 },
            { value: 4, text: "EVAMAT", component: Evamat },
            { value: 6, text: "CLPT", component: Clpt },
          ],
        },
        {
          kind: "d",
          category: "Terapia ocupacional",
          tests: [
            // { value: 13, text: "PRECALCULO", component: Precalculo },
            // { value: 14, text: "FUNCIONES BASICAS", component: Funciones },
            { value: 16, text: "PERFIL SENSORIAL", component: Sensorial },
          ],
        },
      ],
      test_to_category: {
        0: { kind: "b", index: 0 },
        1: { kind: "b", index: 1 },
        2: { kind: "c", index: 0 },
        3: { kind: "a", index: 0 },
        4: { kind: "c", index: 1 },
        5: { kind: "a", index: 1 },
        6: { kind: "c", index: 2 },
        7: { kind: "a", index: 2 },
        8: { kind: "a", index: 3 },
        9: { kind: "a", index: 4 },
        10: { kind: "b", index: 2 },
        11: { kind: "b", index: 3 },
        12: { kind: "c", index: 3 },
        13: { kind: "d", index: 0 },
        14: { kind: "d", index: 1 },
        15: { kind: "b", index: 4 },
        16: { kind: "d", index: 2 },
      },
      completed_tests: [],
    };
  },
  created() {},
  mounted: async function () {
    if (this.preselected_test !== null) {
      let test_data = this.test_to_category[this.preselected_test];
      this.$emit("update-test-kind", test_data.kind);
    }
    if (this.previous_test) {
      let tab = 0;
      this.filtered_tests.forEach((test, index) => {
        if (test.value === this.previous_test) {
          tab = index;
        }
      });
      await new Promise((r) => setTimeout(r, 300));
      this.tab_index = tab;
    }
    console.log(this.tests);
  },
  methods: {
    getPreviousAnswers() {
      testService.getPreviousAnswers(this.last_test).then((data) => {
        this.previousAnswers = data;
      });
    },
    emptyPrevious() {
      this.previousAnswers = {};
    },
    completedTests: function (doc) {
      let required_tests = doc.document_type.document_info.required_tests.map(
        (_t) => _t.test
      );
      let completed_tests = required_tests.filter(
        (elem) => this.student_completed_tests.indexOf(elem) != -1
      );
      return completed_tests.length;
    },
    addTest: function (test) {
      this.tests.unshift(test);
      this.previousAnswers = {};
    },
    viewDocument: function (data) {
      this.$emit("open-templates", data);
    },
    createDocument: function (data) {
      this.$emit("open-templates", data);
    },
    workOnlineDocument(doc, current, total) {
      if (doc.document_type.document_info.document) {
        this.viewDocument(doc.document_type.id);
      } else {
        // if (current != total) {
        //   const msg = `Faltan corregir los otros test, si quieres corregir otros test a este mismo estudiante te
        //   recomendamos corregirlos todos y luego crear el informe. ¿Estás seguro de que quieres crear el informe ahora?`;
        //   this.$bvModal
        //     .msgBoxConfirm(msg, {
        //       okTitle: "SI",
        //       cancelTitle: "NO",
        //     })
        //     .then((value) => {
        //       if (value) {
        //         this.createDocument(doc.document_type.id);
        //       }
        //     });
        // } else {
        //   this.createDocument(doc.document_type.id);
        // }
        console.log(current, total);
        this.createDocument(doc.document_type.id);
      }
    },
    doTest: function (test) {
      const test_data = this.test_to_category[test];
      this.$emit("update-test-kind", test_data.kind);
      this.tab_index = test_data.index;
    },
    deleteTest: function () {
      this.$bvModal
        .msgBoxConfirm("¿Estás seguro de que quieres eliminar el test?", {
          okTitle: "SI",
          cancelTitle: "NO",
        })
        .then((value) => {
          if (value) {
            testService
              .delete_test({ redpsi_id: this.last_test.redpsi_id })
              .then(() => {
                this.$emit("delete-test", this.last_test.redpsi_id);
              });
          }
        });
    },
    updateDocument: function () {
      console.log("update document");
      this.$emit("update-document", this.actual_test);
    },
  },
  computed: {
    filtered_tests: function () {
      return this.tests_by_category.find((cat) => cat.kind == this.test_kind)
        .tests;
    },
    actual_test: function () {
      return this.filtered_tests[this.tab_index].value;
    },
    last_test: function () {
      if (this.tests) {
        return this.tests.find((t) => t.test == this.actual_test);
      }
      return null;
    },
    student_completed_tests: function () {
      return this.tests
        .filter((v, i, a) => a.findIndex((t) => t.test === v.test) === i)
        .map((_t) => {
          return _t.test;
        });
    },
    documents_with_actual_test: function () {
      return this.documents.filter((doc) =>
        doc.document_type.document_info.required_tests
          .map((_t) => _t.test)
          .includes(this.actual_test)
      );
    },
  },
  watch: {
    student: {
      handler() {
        this.previousAnswers = {};
      },
      deep: true,
    },
    preselected_test: {
      async handler() {
        if (this.preselected_test !== null) {
          let test_data = this.test_to_category[this.preselected_test];
          if (test_data.kind !== this.test_kind) {
            this.$emit("update-test-kind", test_data.kind);
          } else {
            let new_tab = 0;
            if (this.preselected_test !== null) {
              this.filtered_tests.forEach((test, index) => {
                if (test.value === this.preselected_test) {
                  new_tab = index;
                }
              });
            }
            await new Promise((r) => setTimeout(r, 300));
            this.tab_index = new_tab;
            this.$emit("clear-preselected");
          }
        }
      },
    },
    test_kind: {
      async handler() {
        let new_tab = null;
        if (this.preselected_test !== null) {
          this.filtered_tests.forEach((test, index) => {
            if (test.value === this.preselected_test) {
              new_tab = index;
            }
          });
        }
        await new Promise((r) => setTimeout(r, 500));
        if (new_tab) {
          this.tab_index = new_tab;
          this.$emit("clear-preselected");
        }
        this.previousAnswers = {};
      },
    },
    previous_test() {
      this.tab_index = this.previous_test;
    },
    tab_index: function () {
      this.previousAnswers = {};
      // this.doing_test = false;
      // this.after_test = false;
    },
  },
};
</script>
<style lang="scss">
.test-div {
  width: 100%;
}
</style>
